import React from 'react';

type TitleOptionProps = {
	readonly color?: string;
	readonly title?: string;
	readonly date?: string;
	readonly logo: string;
};

const TitleOption = ({ color, title, date, logo }: TitleOptionProps): React.ReactElement => (
	<div className="c-jb-title-option">
		<img src={logo} alt={title} className="c-jb-title-option__logo" />
		<div>
			<h2 style={color ? { color } : {}}>{title}</h2>
			<p className="[ c-jb-title-option__date ] [ u-mt-8 ]">{date}</p>
		</div>
	</div>
);

export default TitleOption;
