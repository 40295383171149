import { useEffect, useState, type ReactElement } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import useResizeObserver from 'use-resize-observer';
import YouTube from 'react-youtube';

import AUIButton from '@ancert/aui-button';

import type { IHome } from '../../interfaces/model/IHome';

import logo112 from '../../assets/images/logo-112-sesion.png';
import logoXIX from '../../assets/images/logo-xix-jornada.png';

import { getHomeCards } from '../../services/home.service';

import { ROUTES } from '../../constants';
import { HOMEPAGE } from '../../constants/routes.constants';

const videoProps = {
	height: 'auto',
	width: '100%',
};

const Cards = (): ReactElement => {
	const [cards, setCards] = useState<IHome | undefined>(undefined);
	const history = useHistory();

	const { ref, height } = useResizeObserver<HTMLDivElement>({ box: 'border-box' });

	const loadCards = async () => {
		const { data } = await getHomeCards();
		setCards(data);
	};

	const onClickInscribirse = (type: number) => {
		if (type === 1) {
			history.push(ROUTES.JORNADA_NOTARIAL_INSCRIPCION);
		}

		history.push(ROUTES.SESION_PLENARIA_INSCRIPCION);
	};

	useEffect(() => {
		loadCards();
	}, []);

	return cards ? (
		<div className="c-jb-home__cards">
			<section className="c-jb-home__cards-card">
				<header ref={ref} className="c-jb-home__cards-header">
					<div>
						<p>{cards?.card1.fecha}</p>
						<h2 className="[ c-jb-home__cards-tit ] [ u-mt-8 ]">{cards?.card1.titulo}</h2>
					</div>
					<img className="c-jb-home__cards-img" src={logo112} alt="" />
				</header>
				<div className="c-jb-home__cards-body c-jb-content-text">{parse(cards?.card1.content)}</div>
				<footer className="c-jb-home__cards-footer">
					<NavLink to={ROUTES.SESION_PLENARIA_HOTEL} className="c-jb-home__cards-link">
						Alojamiento
					</NavLink>
					<NavLink to={ROUTES.SESION_PLENARIA_PROGRAMA} className="c-jb-home__cards-link">
						Ver programa
					</NavLink>
					<AUIButton
						className="c-jb-home__cards-btn"
						label="Inscribirse"
						size="lg"
						onClick={() => onClickInscribirse(1)}
					/>
				</footer>
			</section>
			<section className="c-jb-home__cards-card">
				<header className="c-jb-home__cards-header" style={{ height }}>
					<div>
						<p>{cards?.card2.fecha}</p>
						<h2 className="[ c-jb-home__cards-tit ] [ u-mt-8 ]">{cards?.card2.titulo}</h2>
					</div>
					<img className="c-jb-home__cards-img" src={logoXIX} alt="" />
				</header>
				<div className="c-jb-home__cards-body c-jb-content-text">{parse(cards?.card2.content)}</div>
				<footer className="c-jb-home__cards-footer">
					<NavLink to={ROUTES.JORNADA_NOTARIAL_HOTEL} className="c-jb-home__cards-link">
						Alojamiento
					</NavLink>
					<NavLink to={ROUTES.JORNADA_NOTARIAL_PROGRAMA} className="c-jb-home__cards-link">
						Ver programa
					</NavLink>
					<a
						download
						className="c-jb-home__cards-link"
						href="/jornadas-tenerife/documentos/cartel-jornada-notarial-iberoamericana.pdf"
						target="_blank"
						rel="noopener noreferrer"
					>
						Ver cartel
					</a>
					<AUIButton
						label="Inscribirse"
						size="lg"
						className="c-jb-home__cards-btn"
						onClick={() => onClickInscribirse(2)}
					/>
				</footer>
			</section>
			<section className="c-jb-home__cards-card">
				<header className="c-jb-home__cards-header" style={{ height }}>
					<div>
						<p>{cards?.card3.fecha}</p>
						<h2 className="[ c-jb-home__cards-tit ] [ u-mt-8 ]">{cards?.card3.titulo}</h2>
					</div>
				</header>
				<div className="c-jb-home__cards-body c-jb-content-text">
					{parse(cards?.card3.content)}
					<p className="c-jb-home__cards-links">
						<NavLink to={ROUTES.PRESENTACION} className="c-jb-home__cards-link">
							Leer más
						</NavLink>
						<a
							download
							className="c-jb-home__cards-link"
							href={`${HOMEPAGE}/documentos/programa-encuentros-tenerife-2024.pdf`}
							target="_blank"
							rel="noopener noreferrer"
						>
							Descargar programa conjunto
						</a>
					</p>
				</div>
				<footer className="c-jb-home__cards-footer c-jb-home__cards-footer--videos">
					<div className="c-jb-home__cards-videos">
						<figure className="c-jb-home__cards-figure">
							<YouTube className="c-jb-video" videoId={cards?.card3.video1?.id} opts={videoProps} />
							<figcaption className="c-jb-video__caption">{cards?.card3.video1?.title}</figcaption>
						</figure>
						<figure className="c-jb-home__cards-figure">
							<YouTube className="c-jb-video" videoId={cards?.card3.video2?.id} opts={videoProps} />
							<figcaption className="c-jb-video__caption">{cards?.card3.video2?.title}</figcaption>
						</figure>
					</div>
				</footer>
			</section>
		</div>
	) : (
		<></>
	);
};

export default Cards;
