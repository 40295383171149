import { type ReactElement, useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import type { ITab } from '../../interfaces/AUI/ITabNavigation';

import TabNavigation from '../../components/TabNavigation';
import TitleOption from '../../components/TitleOption';

import { TABS } from './tabs/tabs';

import Programa from './tabs/Programa';
import Inscripcion from './tabs/Inscripcion';
import Hotel from './tabs/Hotel';

import AdvertenciaSeguridad from '../../components/AdvertenciaSeguridad';

import { ROUTES } from '../../constants/routes.constants';

import { searchKeyByValue } from '../../utils/routeUtils';

import logoXIX from '../../assets/images/logo-xix-jornada.png';
import type { ITitle } from '../../interfaces/model/ITitle';
import { getJornadaNotarialTitle } from '../../services/title.service';

const TABS_ROUTES: Record<number, string> = {
	// 1: ROUTES.JORNADA_NOTARIAL_PRESENTACION,
	2: ROUTES.JORNADA_NOTARIAL_PROGRAMA,
	3: ROUTES.JORNADA_NOTARIAL_INSCRIPCION,
	4: ROUTES.JORNADA_NOTARIAL_HOTEL,
};

const JornadaNotarial = (): ReactElement => {
	const [title, setTitle] = useState<ITitle | undefined>(undefined);
	const [activeTab, setActiveTab] = useState<ITab['id']>(2);
	const history = useHistory();

	const loadTitle = async () => {
		const { data } = await getJornadaNotarialTitle();
		setTitle(data);
	};

	useEffect(() => {
		loadTitle();
	}, []);

	const onTabChange = (id: ITab['id']) => {
		history.push(TABS_ROUTES[id]);
		setActiveTab(id);
	};

	useEffect(() => {
		console.log(searchKeyByValue(TABS_ROUTES, history.location.pathname));
		setActiveTab(
			history.location.pathname === ROUTES.JORNADA_NOTARIAL
				? 2
				: searchKeyByValue(TABS_ROUTES, history.location.pathname),
		);
	}, [history.location.pathname]);

	return (
		<div className="o-wrapper">
			{activeTab !== 3 && (
				<TitleOption color={title?.color} title={title?.titulo} date={title?.fecha} logo={logoXIX} />
			)}
			<TabNavigation tabs={TABS} activeTab={activeTab} className="u-mt-24" onTabChange={onTabChange} />
			<AdvertenciaSeguridad />
			<div className="u-mt-32">
				<Switch>
					{/*
					<Route
						exact
						path={[ROUTES.JORNADA_NOTARIAL, ROUTES.JORNADA_NOTARIAL_PRESENTACION]}
						component={Presentacion}
					/>
			*/}
					<Route
						exact
						path={[ROUTES.JORNADA_NOTARIAL, ROUTES.JORNADA_NOTARIAL_PROGRAMA]}
						component={Programa}
					/>
					<Route exact path={ROUTES.JORNADA_NOTARIAL_INSCRIPCION} component={Inscripcion} />
					<Route exact path={ROUTES.JORNADA_NOTARIAL_HOTEL} component={Hotel} />
				</Switch>
			</div>
		</div>
	);
};

export default JornadaNotarial;
